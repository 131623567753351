/**
 * This Component is just to redirect the /learn/capella path to the docs site
 */
import React from 'react'

const CapellaLearningPathRedirect = () => {
  return (
    <div>
      <meta http-equiv="refresh" content="0; url=https://docs.couchbase.com/cloud/get-started/create-account.html"/>
      <link rel="canonical" href="https://docs.couchbase.com/cloud/get-started/create-account.html"/>
      <p>
        This Learning Path has moved. Redirecting...
        <br/>
        <a target="_blank" rel='noreferrer' href="https://docs.couchbase.com/cloud/get-started/create-account.html">Click here if you aren't automatically redirected.</a>
      </p>
    </div>
  )
}

export default CapellaLearningPathRedirect
